<template>
  <v-container fluid>
    <v-row >
      <v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
        <strong> {{alerta.texto}} </strong>
        <template v-slot:action="{ attrs }">
          <v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
        </template>
      </v-snackbar>

      <v-btn fab fixed bottom left  color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>

      <v-col cols="12" class="pa-4">
        <v-toolbar class="font-weight-black text-xs-subtitle-1 text-sm-h6 text-md-h5" dark>
          DIRECCIONES DE FACTURACIÓN 
          <v-spacer/>
            <v-btn  color="info" class="hidden-sm-and-down" dark @click="validar_modo_vista(modo=1)"> 
              <v-icon left> mdi-map-marker-plus </v-icon> Agregar dirección   
            </v-btn>
            <v-btn  color="info" class="hidden-md-and-up" dark @click="validar_modo_vista(modo=1)"> 
              <v-icon > mdi-map-marker-plus </v-icon>    
            </v-btn>
            <v-btn small fab color="accent" class="mx-2" @click="initialize()"><v-icon > mdi-autorenew </v-icon></v-btn>
        </v-toolbar>
      </v-col>

      <v-container style="height: 400px;" v-if="Loading">
        <loading/>
      </v-container>

      <v-col cols="12" v-if="!Loading && !direccionesFacturacion.length">
        <v-alert icon="mdi-comment-alert-outline" prominent text color="info" >
          <v-row align="center">
            <v-col class="grow text-h6">
              Actualmente no se cuenta con ninguna dirección de facturación registrada.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>

      <v-col cols="12"  md="6" lg="4" xl="3" v-for="(item, i) in direccionesFacturacion" :key="i" v-else>
        <v-card dark >
           <v-list-item link>
            <v-list-item-content >
              <v-list-item-subtitle class="">
               <span class="font-weight-black subtitle-2">  Nombre: </span> {{ item.nombre }}  {{ item.apellido }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black subtitle-2">  Municipio : </span>  {{ item.municipio}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black subtitle-2">  Colonia : </span>  {{ item.colonia}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
               <span class="font-weight-black subtitle-2">  Calle : </span>  {{ item.calle}} {{ item.numero }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="font-weight-black subtitle-2">  CP: </span> {{ item.cp}}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="font-weight-black subtitle-2">  Telefono: </span> {{ item.telefono }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <span class="font-weight-black subtitle-2">  Correo electronico  </span>{{ item.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="error"     @click="itemAEliminar = item; modalEliminar = true"> <v-icon> mdi-delete </v-icon></v-btn>
            <v-btn color="secondary" @click="validar_modo_vista(modo=2, item)"> <v-icon> mdi-pencil </v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="accent" v-if="item.activo" @click="cambiaActivo(false, item)"><v-icon>mdi-cards-heart</v-icon> </v-btn>
            <v-btn text color="accent" v-else  @click="cambiaActivo(true, item)"> <v-icon> mdi-heart-outline</v-icon> </v-btn>
          </v-card-actions>
        </v-card> 
      </v-col>

      <v-dialog v-model="modalDireccion" width="800">
        <v-card class="pa-4" dark>
          <controlDireccionFacturacion 
          :parametros="parametros"
          :modo="modo"
           @modal="modalDireccion = $event" />
        </v-card>
      </v-dialog>

      <v-dialog v-model="modalEliminar" persistent max-width="500">
        <v-card >
          <v-card-title class="subtitle-1 font-weight-black" > ESTÁ DIRECCIÓN SE ELIMINARA   </v-card-title>
          <v-card-subtitle class="subtitle-2 font-weight-black">¿ ESTA SEGURO DE QUERER CONTINUAR ?</v-card-subtitle>
          <v-divider class="my-0 py-3" ></v-divider>
          <v-card-subtitle align="center" class="red--text font-weight-bold "> SE ELIMINARA DE FORMA DEFINITIVA </v-card-subtitle>
          <v-divider class="my-0 py-2 " ></v-divider>
          <v-card-actions>
            <v-btn dark outlined color="error" @click="modalEliminar = false">No,regresar</v-btn>
            <v-spacer/>
            <v-btn dark color="success" @click="eliminar_direccion()" >Si, Continuar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

		  <overlay v-if="overlay"/>
      
    </v-row>
  </v-container>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex'
  import loading         from '@/components/loading.vue'
  import overlay         from '@/components/overlay.vue'
  import controlDireccionFacturacion from '@/views/Cliente/control_direccion_facturacion.vue'

  export default {
    components:{
      loading,
      overlay,
      controlDireccionFacturacion
    },
    data:()=>({
      direcciones:[],
      Loading: false,
      modalDireccion: false,
      parametros: {},
      modo: 1,
      alerta: { activo: false, texto:'', color:'error', vertical:true },
      overlay: false,

      itemAEliminar: {},
      modalEliminar: false,

    }),

    created () {
      this.initialize();
    },

    computed: {
      ...mapGetters('login',['getusuarioFetish']),
      ...mapGetters('Direcciones',['direccionesFacturacion']),

    },

    methods: {
      ...mapActions('Direcciones',['obtener_direcciones_facturacion']),

      initialize () {
        this.Loading = true; 
        this.obtener_direcciones_facturacion(this.getusuarioFetish.idcliente).finally(()=>{ this.Loading = false})
      },

      validar_modo_vista(modo=1, item= {}){
        this.modo = modo;
        this.parametros = item;
        this.modalDireccion = true;
      },


      cambiaActivo(activo = false, item = {}){
        if(item.activo){
          this.alerta = { activo: true, texto: 'DEBE EXISTIR UNA DIRECCIÓN ACTIVA' , color:'error'}; 
          return;
        };

        const payload = new Object({ 
          idcliente :this.getusuarioFetish.idcliente,
          data      :item,
        })

        this.overlay = true; 
        this.$http.post('cambiar.direccion.facturacion.activa' , payload ).then( response =>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'};
          this.initialize()
        }).catch( error =>{
          console.log('error', error)
					this.alerta = { activo: true, texto: error.body.message , color:'error'};
        }).finally(()=>{ this.overlay = false})
      },

      eliminar_direccion(){
        this.overlay = true; this.modalEliminar = false;
        this.$http.delete('eliminar.direccion.facturacion/' + this.itemAEliminar.idfacturacion ).then( response =>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'};
          this.initialize()
        }).catch( error =>{
          console.log('error', error)
					this.alerta = { activo: true, texto: error.body.message , color:'error'};
        }).finally(()=>{ this.overlay = false})
      }


    },



  }
</script>